























































































import Vue from 'vue';
import DatePicker from 'vue2-datepicker';
import vSelect from 'vue-select';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/zh-tw';
const Ripple = require('vue-ripple-directive');
import { mapState, mapActions } from 'vuex';
import moment from 'moment';
import axios from 'axios';

export default Vue.extend({
  components: {
    DatePicker,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    const date = moment().valueOf();
    return {
      display: false,
      columns: Array<any>(),
      statRows: [],
      rows: [],
      form: {
        date,
        monitorType: undefined,
      },
    };
  },
  computed: {
    ...mapState('monitorTypes', ['monitorTypes']),
  },
  mounted() {
    this.fetchMonitorTypes().then(() => {
      if (this.monitorTypes.length !== 0) {
        this.form.monitorType = this.monitorTypes[0]._id;
      }
    });
  },
  methods: {
    ...mapActions('monitorTypes', ['fetchMonitorTypes']),
    async query() {
      this.display = true;
      const url = `/MonthlyHourReport/${this.form.monitorType}/${this.form.date}`;
      const res = await axios.get(url);
      this.handleReport(res.data);
    },
    handleReport(report: any) {
      this.columns.splice(0, this.columns.length);

      this.columns.push({
        key: 'time',
        label: '日\\時間',
        sortable: true,
      });

      for (let i = 0; i < report.columnNames.length; i++) {
        this.columns.push({
          key: `cellData[${i}].v`,
          label: `${report.columnNames[i]}`,
          sortable: true,
          stickyColumn: true,
        });
      }
      for (const row of report.rows) {
        row.time = moment(row.date).format('MM/DD');
      }
      this.rows = report.rows;
      this.statRows = report.statRows;
    },
  },
});
